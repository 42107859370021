import { TAG_ALIGNMENTS, TAG_ORDERS } from '@wix/communities-blog-client-common';

export const getTagTextAlignment = (tagAlignment) => {
  switch (tagAlignment) {
    case TAG_ALIGNMENTS.left:
      return 'left';
    case TAG_ALIGNMENTS.center:
      return 'center';
    case TAG_ALIGNMENTS.right:
      return 'right';
    default:
      return 'left';
  }
};

export const sortTags = (tags, tagOrder) => {
  switch (tagOrder) {
    case TAG_ORDERS.alphabetical:
      return [...tags].sort((a, b) => a.label.localeCompare(b.label));
    case TAG_ORDERS.mostUsed:
      return [...tags].sort((a, b) => {
        if (a.count < b.count) {
          return 1;
        }
        if (a.count > b.count) {
          return -1;
        }

        return a.label.localeCompare(b.label);
      });
    default:
      return tags;
  }
};
